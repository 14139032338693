.titulo_pag_quemsomos{
    font-size: 40px;
    line-height: 1.1;
    font-weight: 300;
    margin-bottom: 30px;
}

.img_quemsomos{
    width: 400px;
    margin-right: 25px;
}
.rowContainer{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
}

.rowContainer .reverce{
    flex-direction: row-reverse;
}

.tituloQuemSomos{
    line-height: 1.1;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 20px;
}
.descricaoQuemSomos{
    line-height: 1.1;
    font-size: 20px;
    font-weight: 300;
}

.visaoEvalores{
    display: flex;
}

.visaoValoresDetalhe{
    display: inline-flexbox;
}
.img_visaoValores{
    width: 100%;
}
.li_queSomos{
    font-size: 20px;
    font-weight: 300;
}
.ul_quemSomos{
    padding-left: 35px;
    list-style: disc;
}

@media (max-width:1000px){
    .img_quemsomos{
        width: 350px;
    }
}

@media (max-width:780px){
    .rowContainer{
        flex-direction: column;
    }
    .img_quemsomos{
        width: 400px;
    }
    .img_visaoValores{
        width: 400px;
    }
    .visaoEvalores{
        flex-direction: column;
    }
}