
.servico{
    display: flex;
    padding: 15px;
}
.reverce{
    flex-direction: row-reverse;
}
.titulo_servico{
    font-size: 40px;
    line-height: 1.1;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 15px;
}
.container_texto{
    display: inline-block;
}
.descricao_servico{
    line-height: 1.1;
    font-size: 20px;
    font-weight: 300;
}

.img_servico{
    padding: 20px;
    width: 450px;
}

@media (max-width:998px) {
    .servico{
        flex-direction:column;
    }
    .reverce{
        flex-direction:column;
    }
    .titulo_servico{
        text-align: center;
    }
}