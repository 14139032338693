header {
    background-color: #ffffff;
  }

  header .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  
    width: 90%;
    max-width: 1280px;
    margin: auto;
  }
  header img {
    width: 300px;
    margin-left: 20px;
    padding: 15px 0;
  }
  header nav ul {
    display: flex;
  }
  header nav ul li {
    list-style: none;
  }
  header nav ul li a {
    text-decoration: none;
    color: blue;
    text-transform: uppercase;
    font-size: 14px;
    padding: 24px;
    transition: all 250ms linear 0s;
  }
  
  header nav ul li a:hover {
    background: rgba(255,255,255, 0.15)
  }

  .button {
    display: inline-block;
    font-weight: 600;
    color: rgb(60, 75, 153);
    margin: 15px 0px 0px;
    padding: 12px 20px;
    text-decoration: none;
    border-radius: 5px;
    transition: all 150ms linear 0s;
  }
  
  .button:hover {
    opacity: 0.9;
  }

  /*REPONSIVIDADE DO MENU*/

@media (max-width:1110px) {
  header .container{
    flex-direction: column;
  }
  .product-img{
    width: 250px;
  }
  .centralizar{
    justify-content: center;
  }
}
@media (max-width:743px) {
  header .container{
    flex-direction: column;
  }
}

@media (max-width:743px) {
  nav{
    display: none;
    
  }
  header .container {
    flex-direction: row;
    width: 100%;
    padding-right: 0px;

  }
  header img {
    width: 358px;
    margin-left: 116px;
    padding: 15px 0;
  }

  .slider-height-2{
    height: 188px;
  }

  .one,
    .two,
    .three {
        background-color: rgb(60, 75, 153);
        height: 5px;
        width: 100%;
        margin: 6px auto;

        transition-duration: 0.3s;
    }

    .menu-toggle {
        width: 40px;
        height: 30px;
        margin-right: 20px;
    }

    /* fullscreen */
    .menu-section.on {
        position: absolute;
        top:0;
        left:0;
        
        width: 100vw;
        height: 100vh;

        background-color: #ffffff;
        
        z-index: 10;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .menu-section.on nav {
        display: block;
    }

    .menu-section.on .menu-toggle {
        position: absolute;
        right: 25px;
        top: 15px;
    }

    .menu-section.on .menu-toggle .one {
        transform: rotate(45deg) translate(7px, 7px);
    }

    .menu-section.on .menu-toggle .two {
        opacity: 0;
    }

    .menu-section.on .menu-toggle .three {
        transform: rotate(-45deg) translate(8px, -9px);
    }

    .menu-section.on nav ul {
        text-align: center;
        display: block;
    }

    .menu-section.on nav ul a{
        transition-duration: 0.5s;
        font-size: 2rem;
        line-height: 4rem;
        display: block;
    }

    @media(max-width:560px){
      .product-img{
        width: 300px;
        margin: auto;
        border-radius: 10px;
      }
      ul li a .menuButton {
        font-size: 20px;
      }

    }

    @media(max-width:470px){
      header img{
        width: 195px;
      }
      .img{
        padding: 0px;
        margin-left:90px;
      }
    }

}

